<template>
  <div>
    <button class='input-address-button block'
            @click='openAddressMap'>
      {{ addressMapString }}
    </button>
    <vue-daum-postcode v-if='showAddressMap'
                       @complete='completeAddressMap'
                       class='border rounded-md mt-2 overflow-y-scroll'
                       style='max-height: 400px'/>
    <div v-if='hasAddress'
      class='border py-1 px-2 rounded block w-full text-center'>
      {{ postalCode }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditFormFieldAddress',
  props: [
    'metaData',
  ],
  data () {
    return {
      showAddressMap: false,
      address: '',
      postalCode: '',
    }
  },
  watch: {
    addressValue: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('update:addressValue', newVal)
        }
      }, 
      immediate: true,
      deep: true,
    },
  },
  computed: {
    hasAddress () {
      return this.address
    },
    addressMapString () {
      return (this.address) ? this.address : this.placeholder
    },
    addressValue () {
      return {
        address: this.address,
        postalCode: this.postalCode,
      }
    },
    placeholder () {
      return this.metaData && this.metaData.placeholder ? this.metaData.placeholder : ''
    },
  },
  methods: {
    completeAddressMap (event) {
      this.address = event.address
      this.postalCode = event.zonecode
      this.closeAddressMap()
    },
    openAddressMap () {
      this.showAddressMap = true
    },
    closeAddressMap () {
      this.showAddressMap = false
    },
  }
}
</script>

<style type='scss' scoped>
.input-address-button {
  @apply border py-1 px-2 rounded block w-full;
}

.input-address-button:hover {
  border-width: 1.5px;
  font-weight: 600;
  border-color: var(--eventMainColor);
  color: var(--eventMainColor);
}
</style>
